var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',[_c('span',{attrs:{"slot":"header-name"},slot:"header-name"},[_vm._v(_vm._s(_vm.$tf('common.cameras')))]),_c('span',{attrs:{"slot":"header-detail"},slot:"header-detail"},[_vm._v(" "+_vm._s(_vm.$tf('processing_few'))+": "+_vm._s(_vm.cameraStates.active)+", "+_vm._s(_vm.$tf('not_processing_few'))+": "+_vm._s(_vm.cameraStates.disabled)+", "+_vm._s(_vm.$tf('not_working'))+": "+_vm._s(_vm.cameraStates.failed)+", "+_vm._s(_vm.$tf('total'))+": "+_vm._s(_vm.cameraStates.total)+" ")]),_c('div',{attrs:{"slot":"header-actions"},slot:"header-actions"},[_c('el-button',{staticClass:"header-button",attrs:{"name":"create-btn","circle":"","icon":"el-icon-plus","size":"small","disabled":_vm.$hasNoPermission('ffsecurity.add_camera')},on:{"click":function($event){return _vm.$router.push({ path: '/cameras/create/' })}}})],1),_c('table-navigation',{attrs:{"slot":"header-content-navigation","state":_vm.state},slot:"header-content-navigation"}),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.state.loading),expression:"state.loading"}],staticClass:"cameras-table",attrs:{"name":"cameras-table","stripe":"","size":_vm.$vars.sizes.table || _vm.$vars.sizes.common,"data":_vm.state.items,"row-key":"id"},on:{"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"prop":"id","label":_vm.$tf('common.id'),"width":"60"}}),_c('el-table-column',{attrs:{"prop":"image","label":_vm.$tf('common.image'),"width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.showCameraVideo(row.id)}}},[_c('camera-screenshot',{attrs:{"refresh":"","screenshot":row.screenshot,"width":160,"align":"center"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":_vm.$tf('common.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"name":"item-btn","to":{ path: _vm.getItemLink(row) }}},[_vm._v(_vm._s(row.name))]),(row.comment)?_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$tf('common.comment'))+":")]),_vm._v(" "+_vm._s(row.comment)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"group","label":_vm.$tf('common.group'),"width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCameraGroup(row).name || row.group)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"active","label":_vm.$tf('common.active,,1'),"width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-center",on:{"click":function($event){$event.stopPropagation();}}},[_c('el-checkbox',{on:{"change":function (v) { return _vm.activeChange(v, row); }},model:{value:(row.active),callback:function ($$v) {_vm.$set(row, "active", $$v)},expression:"row.active"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"role","width":"160","render-header":_vm.renderStateHeader},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('camera-status',{attrs:{"color":row.state_color,"colorDesc":row.state_color_desc}}),(row.statistic)?_c('span',[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$filters.tShortDuration(row.statistic.processed_duration))+" /")]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(row.statistic.faces_posted)+" /")]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(row.statistic.faces_failed)+" /")]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(row.statistic.frames_dropped)+" /")]),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(row.statistic.job_starts))])]):_c('span',[_vm._v("- / - / - / - / -")])]}}])}),_c('el-table-column',{attrs:{"prop":"role","width":"120","label":_vm.$tf('common.state')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"camera-state"},[_vm._v(_vm._s(_vm._f("cameraStateFormat")(row.state)))]),_c('br'),(row.state === 'NOT_STARTED')?_c('div',[_vm._v(_vm._s(row.health_status && row.health_status.msg))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"width":"100","label":_vm.$tf('action'),"align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-center"},[(!row.external_detector)?_c('el-button',{attrs:{"title":_vm.$tf('restart'),"icon":"el-icon-refresh","circle":"","size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.restart(row)}}}):_vm._e()],1)]}}])})],1)],1),_c('table-navigation',{attrs:{"slot":"footer-content-navigation","state":_vm.state},slot:"footer-content-navigation"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }