<template>
  <page-layout>
    <span slot="header-name">{{ $tf('common.cameras') }}</span>
    <span slot="header-detail">
      {{ $tf('processing_few') }}: {{ cameraStates.active }}, {{ $tf('not_processing_few') }}: {{ cameraStates.disabled }}, {{ $tf('not_working') }}:
      {{ cameraStates.failed }}, {{ $tf('total') }}:
      {{ cameraStates.total }}
    </span>
    <div slot="header-actions">
      <el-button
        name="create-btn"
        class="header-button"
        circle
        icon="el-icon-plus"
        size="small"
        :disabled="$hasNoPermission('ffsecurity.add_camera')"
        @click="$router.push({ path: '/cameras/create/' })"
      />
    </div>
    <table-navigation slot="header-content-navigation" :state="state"></table-navigation>
    <div slot="content">
      <el-table
        name="cameras-table"
        class="cameras-table"
        stripe
        :size="$vars.sizes.table || $vars.sizes.common"
        :data="state.items"
        row-key="id"
        v-loading="state.loading"
        @row-click="rowClick"
      >
        <el-table-column prop="id" :label="$tf('common.id')" width="60"></el-table-column>
        <el-table-column prop="image" :label="$tf('common.image')" width="180px">
          <template slot-scope="{ row }">
            <div @click.stop="showCameraVideo(row.id)">
              <camera-screenshot refresh :screenshot="row.screenshot" :width="160" align="center"></camera-screenshot>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$tf('common.name')">
          <template slot-scope="{ row }">
            <router-link name="item-btn" :to="{ path: getItemLink(row) }">{{ row.name }}</router-link>
            <div v-if="row.comment">
              <strong>{{ $tf('common.comment') }}:</strong> {{ row.comment }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="group" :label="$tf('common.group')" width="120px">
          <template slot-scope="{ row }">
            {{ getCameraGroup(row).name || row.group }}
          </template>
        </el-table-column>
        <el-table-column prop="active" :label="$tf('common.active,,1')" width="120" align="center">
          <template slot-scope="{ row }">
            <div @click.stop class="text-center">
              <el-checkbox v-model="row.active" @change="(v) => activeChange(v, row)"></el-checkbox>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="role" width="160" :render-header="renderStateHeader">
          <template slot-scope="{ row }">
            <camera-status :color="row.state_color" :colorDesc="row.state_color_desc"></camera-status>
            <span v-if="row.statistic">
              <span style="white-space: nowrap">{{ $filters.tShortDuration(row.statistic.processed_duration) }} /</span>
              <span style="white-space: nowrap">{{ row.statistic.faces_posted }} /</span>
              <span style="white-space: nowrap">{{ row.statistic.faces_failed }} /</span>
              <span style="white-space: nowrap">{{ row.statistic.frames_dropped }} /</span>
              <span style="white-space: nowrap">{{ row.statistic.job_starts }}</span>
            </span>
            <span v-else>- / - / - / - / -</span>
          </template>
        </el-table-column>
        <el-table-column prop="role" width="120" :label="$tf('common.state')">
          <template slot-scope="{ row }">
            <span class="camera-state">{{ row.state | cameraStateFormat }}</span>
            <br />
            <div v-if="row.state === 'NOT_STARTED'">{{ row.health_status && row.health_status.msg }}</div>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$tf('action')" align="center">
          <template slot-scope="{ row }">
            <div class="text-center">
              <el-button :title="$tf('restart')" icon="el-icon-refresh" circle size="mini" @click.stop="restart(row)" v-if="!row.external_detector"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <table-navigation slot="footer-content-navigation" :state="state"></table-navigation>
  </page-layout>
</template>

<script>
import CameraStatus from './camera-status.vue';
import CameraScreenshot from './camera-screenshot.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import StatusColumnHeader from './status.column.header.vue';
import PageLayout from '@/components/page/layout';
import CopyToClipboardButton from '@/components/common/copyToClipboardButton';

export default {
  components: {
    CopyToClipboardButton,
    CameraScreenshot,
    TableNavigation,
    CameraStatus,
    PageLayout
  },
  name: 'page-cameras',
  computed: {
    filter() {
      return this.state.filter.current;
    },
    state() {
      return this.$store.state.local_cameras;
    },
    cameraStates() {
      return this.$store.state.stats.cameras;
    }
  },
  created() {
    this.load = this.load.bind(this);
  },
  mounted() {
    this.$store.dispatch(this.$store.state.camera_groups.Action.Get);
    this.$store.dispatch('getStats', 'cameras');
    this.load();
  },
  beforeDestroy() {
    this.$store.dispatch('getAllCameras');
  },
  methods: {
    load() {
      this.$store.dispatch('getStats', 'cameras');
      this.$store.dispatch(this.$store.state.cameras.Action.Get);
    },
    showCameraVideo(cameraId) {
      if (this.$store.getters.isVmsPlayer) {
        const timeStart = 0;
        this.$store.dispatch('showVideoPlayer', { cameraId, timeStart });
      }
    },
    rowClick(item) {
      if (this.$store.state.app.key.ctrl) return;
      this.$router.push({ path: this.getItemLink(item) });
    },
    getCameraGroup(camera) {
      return this.$store.state.camera_groups.items.find((v) => v.id === camera.group) || {};
    },
    getItemLink(item) {
      return '/cameras/' + encodeURIComponent(item.id) + '/';
    },
    restart(item) {
      return this.$store
        .dispatch('restartCamera', { id: item.id })
        .then((v) => this.$store.dispatch(this.state.Action.Get))
        .catch((e) => {
          this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
        });
    },
    renderStateHeader(h, { column }) {
      return h(StatusColumnHeader);
    },
    activeChange(v, item) {
      this.$store.dispatch(this.state.Action.Update, { id: item.id, active: v }).catch((e) => {
        item.active = !v;
        this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
      });
    }
  }
};
</script>
